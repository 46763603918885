<template>
  <div class="container d-flex flex-column flex-root align-items-center mt-30">
    <div class="container--narrow ">
       <div class="logo-holder">
                <img src="media/logos/gmf-logo.png" />
            </div>
            <div v-if="status">
              <div v-if="msg == 'Password reset successfully.'">
                <p style="color:green">{{ msg }}</p>
                <a  href="/#/login"> Click Here to login.</a>
                <br>
              </div>
              <div v-if="msg != 'Password reset successfully.'">
                <p style="color:red">{{ msg }}</p>
              </div>

            </div>
            <div>
                <form
                    class="form"
                    novalidate="novalidate"
                    id="kt_login_signin_form"
                >
                    <div class="form-group">
                      <div class="d-flex justify-content-between mt-n5">
                          <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                          >Password</label
                          >
                      </div>
                      <div
                          id="example-input-group-2"
                          label=""
                          label-for="example-input-2"
                      >
                          <input
                          class="form-control form-control-solid h-auto py-5 px-6 rounded-lg login-input"
                          type="password"
                          name="password"
                          ref="password"
                          placeholder="Enter your password"
                          v-model="form.password"
                          autocomplete="off"
                          />
                      </div>
                    </div>
                    <div class="form-group">
                        <div class="d-flex justify-content-between mt-n5">
                            <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                            >Password Confirm</label
                            >
                        </div>
                        <div
                            id="example-input-group-2"
                            label=""
                            label-for="example-input-2"
                        >
                            <input
                            class="form-control form-control-solid h-auto py-5 px-6 rounded-lg login-input"
                            type="password"
                            name="password_confirmation"
                            ref="password_confirmation"
                            placeholder="Enter your password again"
                            v-model="form.password_confirmation"
                            autocomplete="off"
                            />
                        </div>
                      </div>
                    <div class="pb-lg-0 pb-5">
                    <button
                        ref="kt_login_signin_submit"
                        class="btn btn-primary login-btn font-weight-bolder font-size-h6 px-15 py-4 my-3 mx-0 rounded-lg"
                    >
                        Reset
                    </button>
                    </div>
                </form>
            </div>
            <div>
                <p class="signup-text">
                    <a href="/#/login">Login to getmefood</a>
                </p>
            </div>
    </div>

  </div>
</template>

<style>
    body {
        background: #F8F7FA;
    }
    .logo-holder{
        text-align: center;
        margin-bottom: 30px;
    }
    .login-btn{
        width: 100%;
        background-color: #6666cc !important;
        border-color: #6666cc !important;
    }
    .container--narrow{
        max-width: 550px!important;
        margin: 0 auto;
        width: 100%;
    }
    .login-input{
        font-size: 18px;
        border-color: black !important;
        background-color: #fff !important;
    }
    .signup-text{
        font-size: 16px;
    }

</style>


<script>
import axios from "axios";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import ApiService from "@/core/services/api.service";
// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { RESET_PASSWORD, LOGOUT, LOGIN } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "forgotPasswordReset",
  data() {
    return {
      state: "claim",
      form: {
        password: "",
        password_confirmation: "",
        token: "",

      },
      status: false,
      msg: ""
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["isAuthenticated"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    },

    logoImage() {
        return (
            process.env.BASE_URL + "media/logos/gmf-logo.png"
        );
    },
  },
  mounted() {

    const signin_form = KTUtil.getById("kt_login_signin_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Username is required",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required",
            },
          },
        },
        password_claime: {
          validators: {
            notEmpty: {
              message: "Password Confirm is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv.on("core.form.valid", () => {
      var password = this.form.password;

      this.form.token = this.$route.params.token;



      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      //this.$store.dispatch(SET_COOKIE);
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "sanctum/csrf-cookie")
        .then((e) => {
          this.$store
            .dispatch(RESET_PASSWORD, this.form)
            // go to which page after successfully login
            .then((resp) => {
              //this.$router.push("/dashboard");
              this.status = true;
              this.msg = "Password reset successfully.";
              console.log(resp);
             })
            .catch((error) => {
              this.status = true;
              this.msg = error.response.data.message;
            });

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
  },
};
</script>
